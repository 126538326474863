import memoize from '@github/memoize'

const runtimeEnvironment = memoize(githubEnvironment)

// return the github instance environment
// if dotcom, returns 'dotcom'
// if enterprise, returns 'enterprise' (ghes and ghae both)
function githubEnvironment(): string {
  return document.head?.querySelector<HTMLMetaElement>('meta[name="runtime-environment"]')?.content || ''
}

const isPrivateInstance = memoize(isGitHubPrivateInstance)
function isGitHubPrivateInstance(): boolean {
  return document.head?.querySelector<HTMLMetaElement>('meta[name="is-private-instance"]')?.content === 'true'
}

const isEnterprise = memoize(isEnterpriseEnvironment)
function isEnterpriseEnvironment(): boolean {
  return runtimeEnvironment() === 'enterprise'
}

export {isEnterprise, isPrivateInstance}
